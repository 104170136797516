const toReadableTime = (seconds) => {
  let output = seconds + " seconds";

  if (seconds >= 60) {
    output = (Math.round(seconds / 60)) + " minutes";
  } else if (seconds >= 60 * 60) {
    output = (Math.round(seconds / (60 * 60))) + " hours";
  } else if (seconds >= 60 * 60 * 24) {
    output = (Math.round(seconds / (60 * 60 * 24))) + " days";
  } else if (seconds >= 60 * 60 * 24 * 7) {
    output = (Math.round(seconds / (60 * 60 * 24 * 7))) + " weeks";
  } else if (seconds >= 60 * 60 * 24 * 7 * 4) {
    output = (Math.round(seconds / (60 * 60 * 24 * 7 * 4))) + " months";
  } else if (seconds >= 60 * 60 * 24 * 7 * 4 * 12) {
    output = (Math.round(seconds / (60 * 60 * 24 * 7 * 4 * 12))) + " years";
  } else if (seconds >= 60 * 60 * 24 * 7 * 4 * 12 * 10) {
    output = (Math.round(seconds / (60 * 60 * 24 * 7 * 4 * 12 * 10))) + " decades";
  } else if (seconds >= 60 * 60 * 24 * 7 * 4 * 12 * 10 * 10) {
    output = (Math.round(seconds / (60 * 60 * 24 * 7 * 4 * 12 * 10 * 10))) + " centuries";
  } else if (seconds >= 60 * 60 * 24 * 7 * 4 * 12 * 10 * 10 * 10) {
    output = (Math.round(seconds / (60 * 60 * 24 * 7 * 4 * 12 * 10 * 10 * 10))) + " millennium";
  }

  return output;
}

export default toReadableTime;
