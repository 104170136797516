import convertBlockToRawText from "./convertBlockToRawText";

const calcAvgReadTime = (content) => {
  const text = convertBlockToRawText(content);
  let readTime = 0;

  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    if (char !== " ") {
      readTime += 1 / 25;
    }
  }

  return Math.round(readTime);
};

export default calcAvgReadTime;
