const convertBlockToRawText = (content) => {
  let output = "";

  content.forEach((textSection) => {
    textSection._rawRichText.forEach((block) => {
      if (block.children) {
        block.children.forEach((child) => {
          if (child.text) {
            output += child.text + "\n\n";
          }
        });
      } else {
        output += "\n\n";
      }
    });
  });

  return output;
};

export default convertBlockToRawText;
