import React, { useEffect, useState } from "react";
import client from "../../sanityClient";
import { PortableText } from "@portabletext/react";

import TableOfContents from "../components/blog/TableOfContents";
import SidebarNewsletter from "../components/forms/SidebarNewsletter";

import sanityConnectInfo from "../../sanityConnectInfo.json";
import calcAvgReadTime from "../utils/calcAvgReadTime";
import toReadableTime from "../utils/toReadableTime";
import titleToSlug from "../utils/titleToSlug";
import components from "../utils/componentsForPortableText";

import styled from "styled-components";

const PreviewPageStyles = styled.div`
  .blog {
    padding: 3%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
    }

    .content {
      h2::before {
        display: block;
        content: " ";
        margin-top: -175px;
        height: 175px;
        visibility: hidden;
        pointer-events: none;
      }
    }
  }

  .published {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;

    color: var(--color3);
    margin-bottom: 2rem;

    .blog-info {
      color: var(--dark-grey);
    }

    .share {
      justify-self: end;
    }
  }

  .sidebar {
    .sidebar-newsletter,
    .sidebar-disclosure {
      margin-bottom: 1rem;
    }

    @media (max-width: 1000px) {
      display: none;
    }
  }

  .bottom-about {
    margin-bottom: 1rem;
  }

  .related-articles {
    text-align: center;
  }
`;

const PreviewPage = ({ location }) => {
  const [avgReadTime, setAvgReadTime] = useState(0);
  const [blog, setBlog] = useState();
  const [blogHeads, setBlogHeads] = useState([]);

  useEffect(() => {
    const handlePressR = (e, postId) => {
      if (e.key.toLowerCase() === "r") {
        refreshQuery(postId);
      }
    };

    const searchParams = new URLSearchParams(location.search);
    const postId = searchParams.get("postId");

    if (postId) {
      refreshQuery(postId);

      window.addEventListener("keydown", (e) => handlePressR(e, postId));
    }

    return () => {
      window.removeEventListener("keydown", (e) => handlePressR(e, postId));
    };
  }, [location.search]);

  const refreshQuery = (postId) => {
    const query = `
      *[_type == "blog" && _id == $postId][0] {
        title,
        textEditor[] {
          ...,
          richText[] {
            ...,
            _type == "mainImage" => {"image": {"asset": image.asset -> {...}}},
            _type == "imageInline" => {"image": {..., "image": {"asset": image.image.asset -> {...}}}},
            _type == "infographic" => {"image": {"asset": image.asset -> {...}}},

            markDefs[] {
              ...,
              _type == "internalLinkPreview" || _type == "internalLink" => {"reference": reference -> {
                ...,
                "blogAuthor": blogAuthor -> {...},
                "blogCategory": blogCategory -> {...},
                "image": {"image": {"asset": image.image.asset -> {...}}},
                "relatedPillarPost": relatedPillarPost -> {...},
              }},
            }
          }
        }
      }
    `;
    const params = { postId };

    client
      .fetch(query, params)
      .then((newBlog) => {
        setBlog(newBlog);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (blog) {
      // time to read
      setAvgReadTime(
        toReadableTime(
          calcAvgReadTime(
            blog.textEditor.map((textEditor) => ({
              _rawRichText: textEditor.richText,
            })),
          ),
        ),
      );

      // get h2 titles
      const newBlogHeads = [];
      blog.textEditor.forEach((textSection) => {
        textSection.richText.forEach((block) => {
          if (block.style === "h2") {
            newBlogHeads.push(block.children[0].text);
          }
        });
      });
      setBlogHeads(newBlogHeads);
    }
  }, [blog]);

  return (
    <PreviewPageStyles>
      {blog && (
        <div className="blog">
          <div className="content">
            <h6>Avg read time: {avgReadTime}</h6>

            <h1>{blog.title}</h1>

            {blogHeads.length &&
              blog.textEditor.map((textSection, i) =>
                textSection.richText.find(
                  (block) => block.style === "h2" && blogHeads[i],
                ) ? (
                  <div
                    key={textSection._key}
                    id={titleToSlug(blogHeads[i])}
                    className="blog-section"
                  >
                    <PortableText
                      value={textSection.richText}
                      projectId={sanityConnectInfo.projectId}
                      dataset={sanityConnectInfo.dataset}
                      components={components}
                    />
                  </div>
                ) : (
                  <div key={textSection._key} className="blog-section">
                    <PortableText
                      value={textSection.richText}
                      projectId={sanityConnectInfo.projectId}
                      dataset={sanityConnectInfo.dataset}
                      components={components}
                    />
                  </div>
                ),
              )}
          </div>

          <div className="sidebar">
            {/*<div className="sidebar-disclosure">
            <DisclosureCard disclosure={disclosure} />
          </div>*/}
            <div className="sidebar-newsletter">
              <SidebarNewsletter />
            </div>
            <div className="sidebar-toc">
              <TableOfContents headings={blogHeads} />
            </div>
          </div>
        </div>
      )}
    </PreviewPageStyles>
  );
};

export default PreviewPage;
