import sanityClient from "@sanity/client";
import sanityConnectInfo from "./sanityConnectInfo.json";

const client = sanityClient({
  ...sanityConnectInfo,
  token: process.env.SANITY_TOKEN, // or leave blank for unauthenticated usage
  apiVersion: "2022-08-09",
  useCdn: "false",
});

export default client;
