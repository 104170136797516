import React from "react";
import styled from "styled-components";
import { PortableText } from "@portabletext/react";

import sanityConnectInfo from "../../../sanityConnectInfo.json";
import components from "../../utils/componentsForPortableText";

import SocialMedia from "../utility/SocialMedia";

import Newsletter from "../forms/NewsletterForm";

const SubscribeStyles = styled.div`
  border: 1px solid var(--grey);
  border-radius: 5px;
  background-color: var(--white);
  padding: 10px;

  h2 {
    text-align: center;
  }

  img {
    width: 100%;
    max-width: 100%;
  }

  ul {
    display: flex;
    list-style: none;
    padding-left: 0;

    svg {
      color: var(--black);
    }
  }
`;

export default function SidebarNewsletter({ about, signup }) {
  return (
    <SubscribeStyles>
      <Newsletter signup={signup} />

      <SocialMedia />
    </SubscribeStyles>
  );
}
