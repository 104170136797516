import React, { useEffect, useState } from "react";
import titleToSlug from "../../utils/titleToSlug";

import styled from "styled-components";
import Linka from "../utility/Linka";

const TOCStyles = styled.div`
  background-color: var(--light-grey);
  border: 1px solid var(--grey);
  border-radius: 5px;
  padding: 10px;

  .toc {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
 
    a {
      color: var(--color3);

      &.active {
        color: var(--urls);
      }
    }
  }
`;

export default function TableOfContents({ headings: propHeads }) {
  const [headings, setHeadings] = useState([]);

  useEffect(() => {
    setHeadings(
      propHeads.map((propHead) => ({
        title: propHead,
        slug: titleToSlug(propHead),
      }))
    );
  }, [propHeads]);
  useEffect(() => {
    const handleScroll = () => {
      const observer = new IntersectionObserver((propHeads) => {
        const headingRatios = [];

        propHeads.forEach((heading) => {
          const id = heading.target.getAttribute("id");
          headingRatios.push({ id, ratio: heading.intersectionRatio });
        });

        const sortedRatios = headingRatios.sort((a, b) => b.ratio - a.ratio);

        sortedRatios.forEach((headingRatio, i) => {
          if (i === 0 && headingRatio.ratio > 0) {
            const aElem = document.querySelector(
              `.toc a[href="#${headingRatio.id}"]`
            );
            if (aElem) {
              aElem.classList.add("active");
            }
          } else {
            const aElem = document.querySelector(
              `.toc a[href="#${headingRatio.id}"]`
            );
            if (aElem) {
              aElem.classList.remove("active");
            }
          }
        });
      });

      // Track all sections that have an `id` applied
      document.querySelectorAll("div.blog-section[id]").forEach((sectDiv) => {
        observer.observe(sectDiv);
      });
    };

    document.body.addEventListener("scroll", () => handleScroll());

    return () => {
      document.body.removeEventListener("scroll", () => handleScroll());
    };
  }, [headings]);

  return (
    <TOCStyles>
      <h3>Table of Contents:</h3>
      <div className="toc">
        {headings.map((blogHead) => (
          <Linka key={blogHead.slug} to={`#${blogHead.slug}`}>
            {blogHead.title}
          </Linka>
        ))}
      </div>
    </TOCStyles>
  );
}
